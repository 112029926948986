import { BrowserRouter, Routes, Route } from 'react-router-dom'

//page & component
import Home from './pages/Home'
import Navbar from './components/Navbar'
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
        <div className='pages'>
          <Routes>
            <Route
            path="/"
            element={<Home />} //import it 
            />
          </Routes>
        </div>
      <Footer/>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
