import { Link } from "react-router-dom"

const Navbar = () => 
{
    return(
        <header>
            <div className="container">
                <a href="/">
                    <img src="/Variant Logo Subtle.png" alt="Logo" className="h-8 md:h-10 lg:h-12" />
                </a>
                <Link to="/">
                 Work
                </Link>

                <div className="flex space-x-4">
                <a href="/">Home</a>
                <a href="/">About</a>
                <a href="/">Contact</a>
                </div>

            </div>
        </header>
    )
}

export default Navbar