const Footer = ()  =>  
{
    return(
        <div className="footer">
        <img src="public/footer.png" alt="Subtle Logo"/>
        <p>All Rights Reserved</p>
      </div>
    )
}

export default Footer